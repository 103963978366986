'use client'

import { ServerCrashIcon } from 'lucide-react'
import { useTranslations } from 'next-intl'

export default function Error() {
  const t = useTranslations()
  return (
    <div className="flex min-h-screen w-full flex-col items-center justify-center gap-4 text-center">
      <ServerCrashIcon className="w-10 text-gray-400" />
      <h2 className="text-xl font-semibold">{t('error_500_title')}</h2>
      <p className="max-w-2xl text-center">{t('error_500_description')}</p>
      <h2 className="text-xl font-semibold">{t('error_500_footer')}</h2>
    </div>
  )
}
